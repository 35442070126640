var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-grid-row",
    [
      _c(
        "gov-grid-column",
        { attrs: { width: "two-thirds" } },
        [
          _c("gov-heading", { attrs: { size: "l" } }, [_vm._v("About")]),
          _c("gov-body", [
            _vm._v("Review the content for the about page on the frontend.")
          ]),
          _c("ck-text-input", {
            attrs: {
              value: _vm.frontend.about.title,
              label: "Title",
              error: _vm.errors.get("cms.frontend.about.title"),
              id: "cms.frontend.about.title"
            },
            on: {
              input: function($event) {
                return _vm.onInput({ field: "title", value: $event })
              }
            }
          }),
          _c("ck-wysiwyg-input", {
            attrs: {
              value: _vm.frontend.about.content,
              label: "Content",
              error: _vm.errors.get("cms.frontend.about.content"),
              id: "cms.frontend.about.content"
            },
            on: {
              input: function($event) {
                return _vm.onInput({ field: "content", value: $event })
              }
            }
          }),
          _c("ck-text-input", {
            attrs: {
              value: _vm.frontend.about.video_url,
              label: "Video URL",
              hint: "Only YoutTube and Vimeo URLs supported.",
              error: _vm.errors.get("cms.frontend.about.video_url"),
              id: "cms.frontend.about.video_url",
              type: "url"
            },
            on: {
              input: function($event) {
                return _vm.onInput({ field: "video_url", value: $event })
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }